@import url('https://fonts.googleapis.com/css?family=Source+Code+Pro');
@import url('https://fonts.googleapis.com/css?family=Iceland');
@import url('https://fonts.googleapis.com/css?family=Srisakdi');


.App {
  text-align: left;
  background-color: var(--primaryBackgroundColor);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.tang {
  font-family: 'Srisakdi';
  font-size: calc(20px + 1.5vmin);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: var(--primaryBackgroundColor);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content:center;
  font-size: calc(8px + 1.5vmin);
  color: var(--primaryTextColor);
  font-family: 'Source Code Pro', monospace;  
  margin-left: calc(20px + 3vmin);
  text-justify: auto;
}

.vkp {
  font-size: calc(20px + 3vmin);
  font-family: 'Source Code Pro', monospace;  
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html {
  /* our light-mode / default theme variable values */
  --primaryTextColor: #17202A;
  --primaryBackgroundColor: #EAECEE;

}

html.dark {
  /* here's where we change the variable values for dark mode */
  --primaryTextColor: #EAECEE;
  --primaryBackgroundColor: #17202A;
    
}




.type {
  border-right: solid 3px var(--primaryBackgroundColor);
  white-space: nowrap;
  overflow: hidden;    
  font-family: 'Iceland';
  font-size: calc(6px + 6vmin);
  color: var(--primaryTextColor);
  
  text-align: left; 
}

/* Animation */
.type {
  animation: animated-text 1.5s steps(500,end) 0.5s 1 normal both,
             animated-cursor 1s steps(60,end) infinite;
}

/* text animation */

@keyframes animated-text{
  from{width: 0;}
  to{width: calc(30px + 60vmin);}
}

/* cursor animations */

@keyframes animated-cursor{
  from{border-right-color: var(--primaryTextColor);}
  to{border-right-color: transparent;}
}


.button {
  margin-top: 25px;
  margin-right: 25px;
  position:absolute;
  top:0;
  right:0;
}

.social {
  position:'absolute';
  text-align: center;
  overflow: hidden;
  text-align: center;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.marginLR15 {
  margin-right: 25px;
  margin-left: 25px;
  bottom: 0;
}

.inbl {
  display: inline-block;
}

.scaleOnHover {
  transition: all 0.2s linear;
  
}

.scaleOnHover:hover {
  transform: scale(1.3);
  
}

.ico25{
  font-size: 25px;
}

.col {
  color: var(--primaryTextColor);
}

